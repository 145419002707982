import { render, staticRenderFns } from "./team-members.html?vue&type=template&id=430a72c6&scoped=true&"
import script from "./team-members.vue?vue&type=script&lang=ts&"
export * from "./team-members.vue?vue&type=script&lang=ts&"
import style0 from "./team-members.scss?vue&type=style&index=0&id=430a72c6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "430a72c6",
  null
  
)

export default component.exports