


import {Component, Prop, Vue} from 'vue-property-decorator';
import {TPromoPage} from '@/_types/promo-page/promo-page.type';
import {TPromoPageAttachedContacts} from '@/_types/promo-page/promo-page-attached-contacts.type';
import {TMeeting} from '@/_types/meeting/meeting.type';
import {TContact} from '@/_types/contact.type';
import TeamMember from '@/_modules/standalone-company/components/team-members/team-member/team-member.vue';
import EwPopUp from '@/_modules/standalone-company/components/ew-pop-up/ew-pop-up.vue';
import EwButton from '@/_modules/standalone-company/components/UI/Ew-Button/Ew-Button.vue';
import ContactData from '@/_modules/standalone-company/components/parts/contact-data/contact-data.vue';
import MeetingTimePicker from '@/_modules/standalone-company/components/meeting-time-picker/meeting-time-picker.vue';
import DateTimeHelper from '@/_helpers/date-time.helper';
import {Action, Getter} from 'vuex-class';
import {TSimpleTimeSlot} from '@/_modules/standalone-company/types/standalone-company-store-state.type';
import {MeetingStatus} from '@/_modules/meeting-rooms/types/meeting-status.enum';
import EwCheckbox from '@/_modules/standalone-company/components/UI/ew-checkbox/ew-checkbox.vue';
import EwAuth from '@/_modules/standalone-company/components/ew-auth/ew-auth.vue';

@Component({
  components: {
    TeamMember,
    EwPopUp,
    EwButton,
    EwCheckbox,
    ContactData,
    MeetingTimePicker,
    EwAuth
  }
})
export default class TeamMembers extends Vue {

  @Getter('authStore/isAuthenticated') isAuthenticated: boolean;
  @Getter('promoPageStore/contact') myself: TContact;
  @Getter('standaloneCompanyStore/getTimeSlotSelection') public readonly timeSlotSelection: TSimpleTimeSlot[];
  @Action('standaloneCompanyStore/clearTimeSlotSelection') clearTimeSlotSelection: () => void;
  @Action('authStore/setShowStandaloneAuth') setShowStandaloneAuth: (params: boolean) => Promise<any>;
  @Action('meetingsStore/requestUserMeetings') public requestUserMeetings: (params: { userId: number; force?: boolean }) => Promise<TMeeting[]>;

  public isShowScheduleMeetingPopUp: boolean = false;
  public isShowSentRequestPopUp: boolean = false;
  public isShowErrorRequestPopUp: boolean = false;
  public isShowCancelRequestPopUp: boolean = false;
  public chosenContact: TContact;
  public isSaveInProgress: boolean = false;
  public isCancellationInProgress: boolean = false;
  public savedMeetingsTexts: string[] = []; // For each saved meeting a text must be built and displayed
  public canceledMeetings: TMeeting[] = [];
  public canceledMeetingsIdsToSelected: { [key: number]: boolean } = null;

  public monthNames: string[] = [
    this.$t('datepicker.monthNamesFull.january') as string,
    this.$t('datepicker.monthNamesFull.february') as string,
    this.$t('datepicker.monthNamesFull.march') as string,
    this.$t('datepicker.monthNamesFull.april') as string,
    this.$t('datepicker.monthNamesFull.may') as string,
    this.$t('datepicker.monthNamesFull.june') as string,
    this.$t('datepicker.monthNamesFull.july') as string,
    this.$t('datepicker.monthNamesFull.august') as string,
    this.$t('datepicker.monthNamesFull.september') as string,
    this.$t('datepicker.monthNamesFull.october') as string,
    this.$t('datepicker.monthNamesFull.november') as string,
    this.$t('datepicker.monthNamesFull.december') as string,
  ];

  public dayNames: string[] = [
    this.$t('standaloneCompany.dayNames.sunday') as string,
    this.$t('standaloneCompany.dayNames.monday') as string,
    this.$t('standaloneCompany.dayNames.tuesday') as string,
    this.$t('standaloneCompany.dayNames.wednesday') as string,
    this.$t('standaloneCompany.dayNames.thursday') as string,
    this.$t('standaloneCompany.dayNames.friday') as string,
    this.$t('standaloneCompany.dayNames.saturday') as string,
  ];

  @Prop()
  public readonly company: TPromoPage;

  @Prop()
  public readonly myMeetings: TMeeting[];

  public get eventId(): number {
    return this.$route.params.eventId ? parseInt(this.$route.params.eventId) : null;
  }

  public get attachedContacts(): TPromoPageAttachedContacts[] {
    return this.company && this.company.attached_contacts;
  }

  public get getChosenContact(): TContact {
    return this.chosenContact;
  }

  public closeScheduleMeetingPopUp(): void {
    this.isShowScheduleMeetingPopUp = false;
  }

  public closeSentRequestPopUp(): void {
    this.hideScheduleSuccessPopup();
    this.savedMeetingsTexts = [];
  }

  public closeErrorRequestPopUp(): void {
    this.isShowErrorRequestPopUp = false;
  }

  public closeCancelRequestPopUp(): void {
    this.isShowCancelRequestPopUp = false;
    this.canceledMeetings = [];
    this.canceledMeetingsIdsToSelected = null;
  }

  public scheduleMeetingEvent(contactItem: TContact): void {

    this.closeCancelRequestPopUp();
    this.hideScheduleSuccessPopup();

    if (this.isAuthenticated) {
      this.showScheduleMeetingPopup();
      this.chosenContact = contactItem;
    } else {
      this.setShowStandaloneAuth(true);
    }

    // this.isShowErrorRequestPopUp = true;
  }

  public get isScheduleMeetingButtonDisabled(): boolean {
    return (this.isSaveInProgress || !this.timeSlotSelection || this.timeSlotSelection.length <= 0);
  }

  private onScheduleMeetingsButtonClick(): void {
    this.saveMeetings();
  }

  private async saveMeetings(): Promise<void> {
    const saveResult: {timeSlot: TSimpleTimeSlot; meetingId: number}[] = [];
    this.isSaveInProgress = true;
    for (const timeSlot of this.timeSlotSelection) {
      if (timeSlot && timeSlot.dateStart && timeSlot.dateEnd) {
        const requestResult = await this.$store.dispatch('meetingsStore/requestMeeting', {
          event_id: this.eventId,
          user_id: this.chosenContact.user_id,
          date_start: DateTimeHelper.dateToApiDate(timeSlot.dateStart),
          date_end: DateTimeHelper.dateToApiDate(timeSlot.dateEnd),
        });

        saveResult.push({
          timeSlot: {...timeSlot},
          meetingId: requestResult.status === 200 && requestResult.data && requestResult.data.meetingId ? requestResult.data.meetingId : null
        });
      }
    }

    this.isSaveInProgress = false;

    // TODO: чистить только успешно сохраненные тайм-слоты, смотреть по saveResult

    this.prepareSuccessTexts(saveResult);

    this.hideScheduleMeetingPopup();
    this.showScheduleSuccessPopup();

    this.clearTimeSlotSelection();
    this.requestUserMeetings({
      userId: this.myself.user_id,
      force: true
    });
  }

  private prepareSuccessTexts(requestMeetingsResult: {timeSlot: TSimpleTimeSlot; meetingId: number}[]): void {
    this.savedMeetingsTexts = requestMeetingsResult.map(item => {
      return this.getFormattedMeetingInfo(item.timeSlot.dateStart, item.timeSlot.dateEnd);
    });
  }

  // TODO: move into a helper
  private getFormattedMeetingInfo(dateStart: Date, dateEnd: Date): string {
    const diffMinutes: number = Math.floor((dateEnd.getTime() - dateStart.getTime()) / 1000 / 60);
    const year: number = dateStart.getFullYear();
    const monthName: string = this.monthNames[dateStart.getMonth()];
    const dayName: string = this.dayNames[dateStart.getDay()];
    const dayNumber: number = dateStart.getDate();
    const hhStart: number = dateStart.getHours();
    const mmStart: number = dateStart.getMinutes();
    const hhEnd: number = dateEnd.getHours();
    const mmEnd: number = dateEnd.getMinutes();

    return this.$t('standaloneCompany.meetingSuccessTemplate', {
      diffMinutes,
      year,
      monthName,
      dayNumber,
      dayName,
      hhmmStart: hhStart.toFixed(0).padStart(2, '0') + ':' + mmStart.toFixed(0).padStart(2, '0'),
      hhmmEnd: hhEnd.toFixed(0).padStart(2, '0') + ':' + mmEnd.toFixed(0).padStart(2, '0'),
    }) as string;
  }

  private showScheduleMeetingPopup(): void {
    this.isShowScheduleMeetingPopUp = true;
  }

  private hideScheduleMeetingPopup(): void {
    this.isShowScheduleMeetingPopUp = false;
  }

  private showScheduleSuccessPopup(): void {
    this.isShowSentRequestPopUp = true;
  }

  private hideScheduleSuccessPopup(): void {
    this.isShowSentRequestPopUp = false;
  }

  private showCancellationPopup(): void {
    this.isShowCancelRequestPopUp = true;
  }

  private onCancelMeetings(meetings: TMeeting[], contact: TContact): void {

    this.hideScheduleMeetingPopup();
    this.hideScheduleSuccessPopup();

    this.chosenContact = contact;
    this.canceledMeetings = meetings.filter(meeting => {
      return (
        meeting.status === MeetingStatus.Confirmed
        || meeting.status === MeetingStatus.Unconfirmed
      );
    });
    this.canceledMeetingsIdsToSelected = {};
    this.canceledMeetings.forEach(meeting => {
      this.canceledMeetingsIdsToSelected[meeting.id] = false;
    });
    this.showCancellationPopup();
  }

  private onCanceledMeetingsSelection(meetingId: number): void {
    const currentObject: any = {...this.canceledMeetingsIdsToSelected};
    currentObject[meetingId] = !currentObject[meetingId];
    this.canceledMeetingsIdsToSelected = Object.assign({}, this.canceledMeetingsIdsToSelected);
  }

  private onCancelSelectedMeetingsClick(): void {
    this.cancelMeetings();
  }

  private async cancelMeetings(): Promise<void> {
    this.isCancellationInProgress = true;
    const toCancel: string[] = Object.keys(this.canceledMeetingsIdsToSelected);
    for (const meetingId of toCancel) {
      if (this.canceledMeetingsIdsToSelected[parseInt(meetingId, 10)] === true) {
        await this.$store.dispatch('meetingsStore/cancelMeeting', {
          event_id: this.eventId,
          meeting_id: parseInt(meetingId, 10),
        });
      }
    }

    this.isCancellationInProgress = false;

    this.closeCancelRequestPopUp();

  }
}
